// CRA inserting a parasitic iframe when hot-deploying
html body > iframe:last-child {
  display: none !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $color-grey-50;
}

.content {
  &.content-fluid {
    @media (max-width: 1500px) {
      width: 95%;
    }
    width: 100%;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
}

.main-content {
  flex: 1;
  min-height: 0;
  margin-top: 1em;
  // TODO: This breaks components like dropdowns that flow out of the content. (overflow: hidden)
  overflow: hidden;
}

.dbg {
  margin: 0.5em;
  padding: 0.5em;
  background: rgba(17, 50, 244, 0.5);
  border: 1px solid rgba(255, 40, 20, 0.75);
}

.btn-primary:not(:disabled):not(.disabled):active {
  border-color: $color-green-2;
}

.Toastify {
  .Toastify__toast {
    min-height: 50px;
    border-radius: 0.5em;
    .toast-content {
      @include flex;
      align-items: center;
      .toast-icon {
        @include flex;
        flex: 0 0 2em;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        margin-right: 0.5em;
        overflow: hidden;
        border-radius: 50%;
        .icon {
          color: $color-white;
          font-size: 0.75em;
        }
      }
    }

    /* prettier-ignore */
    &.Toastify__toast--info {
      background: change-color($color-blue-dg, $lightness: 90%);
      .toast-content .toast-icon { background: $color-blue-dg; }
      .Toastify__close-button { color: $color-blue-dg; }
    }

    /* prettier-ignore */
    &.Toastify__toast--success {
      background: change-color($color-green-dg, $lightness: 90%);
      .toast-content .toast-icon { background: $color-green-dg; }
      .Toastify__close-button { color: $color-green-dg; }
    }

    /* prettier-ignore */
    &.Toastify__toast--warning {
      background: change-color($color-yellow-dg, $lightness: 90%);
      .toast-content .toast-icon { background: $color-yellow-dg; }
      .Toastify__close-button { color: $color-yellow-dg; }
    }

    /* prettier-ignore */
    &.Toastify__toast--error {
      background: change-color($color-red-2, $lightness: 90%);
      .toast-content .toast-icon { background: $color-red-2; }
      .Toastify__close-button { color: $color-red-2; }
    }

    .toast-inner-content-wrap {
      max-width: 250px;
      overflow: hidden;
      line-height: 1.2;

      .toast-type {
        font-weight: $typography-font-weight-bolder;
      }
    }

    .Toastify__close-button {
      line-height: 1;
    }
  }
}

.link-color {
  color: $color-blue-dg !important;
}

.space-left {
  margin-left: 0.5em;
}

.space-right {
  margin-right: 0.5em;
}
