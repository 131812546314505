@import 'scss/vars.scss';

.new-job-section {
  @include block;
  padding: 1em 1.5em 1.5em;

  .title {
    font-size: 2rem;
    // font-weight: $typography-font-weight-bolder;
    margin-bottom: 0.5em;
  }

  .uploaded-file-block {
    font-size: 1.25rem;
    font-weight: $typography-font-weight-bold;
    margin: 0.5em 0;
    .label {
      color: $color-grey-500;
    }
    .value {
      font-weight: $typography-font-weight-bolder;
    }
  }

  .actions {
    margin-top: 2rem;

    :not(:first-child) {
      margin-left: 1rem;
    }

    .btn {
      min-width: 3em;

      &.btn-cancel {
        background-color: $color-red-dg;
        border-color: $color-red-dg;
        &:hover,
        &:active,
        &:focus {
          border-color: scale-color($color: $color-red-dg, $lightness: -20%);
          background: linear-gradient(180deg, $color-red-dg 0%, $color-red-dg-light 500%);
          box-shadow: 0 1px 3px 0 $color-red-dg;
        }
      }
    }
  }
}

.uploader-panel {
  .uploading-text {
    font-weight: bold;
    font-size: large;
  }

  .uploading-header {
    margin-bottom: 1rem;
  }
  .file-upload-template-wrapper {
    .template-upload-attachment {
      // height: 13rem;

      .supported-formats-wrapper {
        @include flex;
        align-items: stretch;

        .formats-row-item {
          @include flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1em;
          .af-title {
            margin-bottom: 0.25em;
          }
          .file-upload-allowed-files {
            @include flex;
            gap: 0.5em;

            .file-item {
              img {
                height: 32px;
                //opacity: 0.5;
              }
            }
          }
        }

        span.separator {
          width: 1px;
          margin: 0.5em 1em;
          background: #757d8d;
        }
      }
    }
  }
}
