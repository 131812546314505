@import '../../../scss/vars';

.custom-bot-config {
  .custom-bot-config-selector {
    & > .custom-bot-config-selector-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;

      & > .title {
        display: flex;
        align-items: center;

        padding-left: 0.5rem;
        padding-bottom: 0.15rem;
        margin-bottom: 0.5rem;
        color: $color-grey-500;
        font-weight: 600;
      }

      & > .btn-edit {
        &.enabled {
        }
      }
    }
    > .custom-bot-config-selector-body {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;

      > .custom-bot-config-selector-body-select {
        flex-grow: 1;
        margin-right: 0.5rem;
      }

      > .btn {
        flex-grow: 0;
      }
    }
  }
}
