.header-mapping-table {
  .header-mapping-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    td:first-child {
      flex: 1;
      font-weight: bold;
      padding-right: 2em;
    }
    td:nth-child(2) {
      flex: 3;
      width: 100%;
    }
  }
}
