@import 'scss/vars.scss';

.ids-short-description {
  margin-bottom: 2em;
}

.ids-summary-wrapper {
  @include block;
  padding: 1em;
  overflow-x: auto;

  > .header {
    @include flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 2em;
    }

    .date {
      font-size: 0.75em;
      color: #9e9e9e;
      vertical-align: bottom;
      line-height: 30px;
      margin-left: 1em;
    }
  }

  .empty-statistics {
    @include flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 0.25em;
      margin-top: 0.25em;
    }
  }

  .ids-summary {
    @include flex;

    align-items: stretch;
    justify-content: space-between;
    margin-top: 2em;
    min-width: 1300px;

    > section.circular-progress-bar-wrapper {
      padding: 1em;
      flex: 0 0 25em;

      > div.circular-progress-bar-wrapper {
        margin: 1em 0;

        .bai-circular-progressbar.semi-circle {
          position: relative;
          padding-top: 50%;
          overflow: hidden;

          &:first-child {
            margin-bottom: 0.5em;
          }

          &:last-child {
            margin-top: 0.5em;
          }
        }
      }
    }

    .value-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-auto-flow: dense;
      grid-gap: 1em;
      margin-right: 2em;

      .span-2r {
        // span 2 rows
        grid-column: span 2;
      }

      > div {
        flex: 1 1 50%;

        > .bai-value-block {
          width: 100%;
          &:first-child {
            margin-right: 0.5em;
          }

          &:last-child {
            margin-left: 0.5em;
          }
        }
      }

      .inner-row {
        display: flex;
        flex-direction: row;

        .bai-value-block > .additional-data {
          display: flex;
        }
      }
    }
  }
}
