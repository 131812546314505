@import 'scss/vars.scss';

.view-home-screen {
  .title h1 {
    font-size: 2rem;
  }

  .home-screen-top-links {
    margin-top: 2em;

    .bai-cards-grid {
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;

      .bai-card {
        flex: 0 0 30%;
        width: 30%;
        max-width: none;
        margin: 0;
      }
    }
  }

  .home-screen-data-jobs {
    margin-top: 3em;
  }
}

@media screen and (max-width: 1200px) {
  .view-home-screen {
    .home-screen-top-links {
      .bai-cards-grid {
        .bai-card {
          flex: 0 0 32%;
          width: 32%;
          height: 250px;
          .bai-card-body {
            height: 250px;
            .bai-card-icon {
              height: 120px;
            }

            .bai-card-header {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .view-home-screen {
    .home-screen-top-links {
      .bai-cards-grid {
        .bai-card {
          flex: 0 0 32%;
          width: 32%;
          height: 290px;
          .bai-card-body {
            height: 290px;
            .bai-card-icon {
              height: 100px;
              font-size: 4em;
            }
          }
        }
      }
    }
  }
}
